import ROUTES from '@/app/routes'
import { supabaseClient } from './supabase'

export const redirectToLogin = () => {
  // Add from to the state so we can redirect back to the page after login
  const redirect = encodeURIComponent(
    window.location.pathname + window.location.search,
  )
  return window.location.replace(`${ROUTES.LOGIN.href}?redirect=${redirect}`)
}

export const signOut = async () => {
  await supabaseClient.auth.signOut()
  return window.location.replace(ROUTES.HOME.href)
}

export const getCurrentUser = async () => {
  const user = (await supabaseClient.auth.getSession())?.data?.session?.user
  return user
}

export const getUser = async () => {
  return (await supabaseClient.auth.getUser()).data.user
}
