import classNames from 'classnames'

export default function Section({
  children,
  altBg = false,
  className,
}: {
  children?: React.ReactNode
  altBg?: boolean
  className?: string
}) {
  return (
    <section
      className={classNames(
        'py-24 w-full',
        altBg && 'bg-neutral-50',
        className,
      )}
    >
      <div className="mx-auto max-w-[1440px] px-8">{children}</div>
    </section>
  )
}
