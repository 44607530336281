import '../app/globals.css'
import React, { useEffect, useState } from 'react'
import { companyNames, humanFirstNames, jobTitles } from '@/data/data'
import { Card, CardContent } from '@/components/ui/card'

const ReferralWidget: React.FC = () => {
  const [mentorName, setMentorName] = useState('')
  const [menteeName, setMenteeName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [key, setKey] = useState(0) // Adding key state

  useEffect(() => {
    getRandomNames() // Initial call to get random names

    const intervalId = setInterval(
      () => {
        getRandomNames() // Refresh names every 3 to 7 seconds
      },
      getRandomInterval(3000, 7000),
    )

    return () => clearInterval(intervalId) // Cleanup on unmount
  }, [])

  const getRandomInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const getRandomNames = () => {
    const randomIndexOfMentor = Math.floor(
      Math.random() * humanFirstNames.length,
    )
    const randomIndexOfMentee = Math.floor(
      Math.random() * humanFirstNames.length,
    )
    const randomIndexOfCompanynName = Math.floor(
      Math.random() * companyNames.length,
    )
    const randomIndexOfJobTitle = Math.floor(Math.random() * jobTitles.length)
    const mentorName = humanFirstNames[randomIndexOfMentor]
    const anonymisedMentorName = `${mentorName.charAt(
      0,
    )}****${mentorName.charAt(mentorName.length - 1)}`
    setMentorName(anonymisedMentorName)
    setMenteeName(humanFirstNames[randomIndexOfMentee])
    setCompanyName(companyNames[randomIndexOfCompanynName])
    setJobTitle(jobTitles[randomIndexOfJobTitle])
    setKey((prevKey) => prevKey + 1) // Update key to trigger re-render
  }

  return (
    <Card
      key={key} // Use key prop to force re-render
      className="animate-fade-up animate-once animate-ease-in fixed flex flex-row items-center mx-4 sm:mx-0 justify-center sm:right-5 bottom-5 z-[2] shadow bg-neutral-200"
    >
      <CardContent className="space-y-2">
        <p className="text-sm">Looking for a referral to {companyName}</p>
        <p className="text-xs">
          {mentorName} referred {menteeName} to {companyName} for a {jobTitle}{' '}
          role
        </p>
      </CardContent>
    </Card>
  )
}

export default ReferralWidget
