'use client'
import Link from 'next/link'
import { Button } from './ui/button'
import ROUTES from '@/app/routes'
import { signOut } from '@/lib/auth'
import { useAuth } from '@/contexts/AuthContext'

export default function AuthButton() {
  const { me } = useAuth()

  return me ? (
    <div className="flex items-center gap-4">
      Hey {me.name?.firstName?.replace(/@.*/, '') || me.email?.replace(/@.*/, '')}
      <form action={signOut}>
        <button className="py-2 px-4 rounded-md no-underline bg-btn-background hover:bg-btn-background-hover">
          Logout
        </button>
      </form>
    </div>
  ) : (
    <div className="flex items-center gap-4">
      <Button className="text-sm" asChild>
        <Link href={ROUTES.SIGNUP.href}>Join</Link>
      </Button>
      <Button className="text-sm" variant="ghost" asChild>
        <Link
          href={ROUTES.LOGIN.href}
          className="py-2 px-3 flex rounded-md no-underline bg-btn-background hover:bg-btn-background-hover"
        >
          Login
        </Link>
      </Button>
    </div>
  )
}
