import AuthButton from './AuthButton'
import Nav from './Nav'

export default function Header({
  showNav = true,
  className,
}: {
  showNav?: boolean
  className?: string
}) {
  return <Nav>{showNav && <AuthButton />}</Nav>
}
