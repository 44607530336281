'use client'
import Link from 'next/link'
import classNames from 'classnames'
import { footerMenu } from '@/app/menu'
import ROUTES from '@/app/routes'
import { usePathname } from 'next/navigation'

const PRICING_DISCLAIMER_ROUTES = [ROUTES.MY_REFERRALS.href]

export default function Footer({
  className,
  fullWidth = false,
}: {
  className?: string
  fullWidth?: boolean
}) {
  const pathname = usePathname()
  const showPriceDisclaimer = PRICING_DISCLAIMER_ROUTES.includes(pathname)

  return (
    <footer
      className={classNames(
        'mx-auto p-8 w-full',
        !fullWidth && 'max-w-[1440px]',
        className,
      )}
    >
      <div className="flex flex-col items-center text-center md:text-left md:flex-row md:justify-between text-secondary-foreground/50 gap-2">
        <span className="text-xs">© 2024 CareerParty</span>
        {showPriceDisclaimer && (
          <span className="text-xxs text-secondary-foreground/50 mx-auto">
            *All prices shown are estimates and are subject to applicable taxes
            and platform fees.
          </span>
        )}
        <nav className="flex flex-wrap space-x-4 justify-center">
          {footerMenu.map((item, idx) => (
            <Link
              key={idx}
              href={item.path}
              className="text-xs"
              target={item?.openExternal ? '_blank' : '_self'}
            >
              {item.title}
            </Link>
          ))}
        </nav>
      </div>
    </footer>
  )
}
