'use client'
import Link from 'next/link'

import Logo from './Logo'
import classNames from 'classnames'
import { useState } from 'react'
import { Menu } from 'lucide-react'
import { mainMenu } from '@/app/menu'

export default function Nav({
  showNav = true,
  className,
  children,
}: {
  showNav?: boolean
  className?: string
  children?: React.ReactNode
}) {
  const [isNavOpen, setIsNavOpen] = useState(false)

  return (
    <nav className="bg-white w-full border-b md:border-0">
      <div className="items-center px-4 max-w-screen-xl mx-auto md:flex md:px-8">
        <div className="flex items-center justify-between py-3 md:py-5 md:block">
          <Link href="/">
            <Logo />
          </Link>
          <div className="md:hidden">
            <button
              className="text-secondary-foreground outline-none p-2 rounded-md focus:border-neutral-200 focus:border"
              onClick={() => setIsNavOpen(!isNavOpen)}
            >
              <Menu />
            </button>
          </div>
        </div>
        <div
          className={classNames(
            'flex-1 justify-self-center pb-3 mt-8 md:flex md:flex-row md:pb-0 md:mt-0 md:justify-between md:ml-8',
            isNavOpen ? 'block' : 'hidden',
          )}
        >
          <ul className="justify-center items-center space-y-8 md:flex md:space-x-6 md:space-y-0">
            {mainMenu.map((item, idx) => (
              <li
                key={idx}
                className="text-secondary-foreground hover:text-rose-600"
              >
                <Link href={item.path}>{item.title}</Link>
              </li>
            ))}
          </ul>
          <div className="mt-8 md:mt-0">{children}</div>
        </div>
      </div>
    </nav>
  )
}
