export const companyNames = [
  'Google',
  'Apple',
  'Amazon',
  'Microsoft',
  'Facebook',
  'Adobe',
  'Salesforce',
  'Intel',
  'Oracle',
  'Twitter',
  'Uber',
  'Airbnb',
  'Netflix',
  'LinkedIn',
  'Cisco',
  'PayPal',
  'Dropbox',
  'Zoom',
  'Twitch',
  'Pinterest',
  'Reddit',
  'Slack',
  'Square',
  'Stripe',
  'Evernote',
  'Yelp',
  'Zynga',
  'Electronic Arts',
  'Hulu',
  'Lyft',
  'DoorDash',
  'Snapchat',
  'VMware',
  'Tesla',
  'NVIDIA',
  'Palantir Technologies',
  'Roku',
  'Qualcomm',
  'Box',
  'Twilio',
  'Splunk',
  'Okta',
  'Workday',
  'MongoDB',
  'Atlassian',
  'DocuSign',
  'Roblox',
  'Pinterest',
  'WeWork',
  'Wix',
  'Unity Technologies',
  'Quora',
  'Zillow',
  'OkCupid',
  'SurveyMonkey',
  'Groupon',
  'Couchbase',
  'SquareSpace',
  'Cloudera',
  'Looker',
  'Docker',
  'GitLab',
  'PagerDuty',
  'Zendesk',
  'Instacart',
  'Coinbase',
  'Reddit',
  'Asana',
  'Coursera',
  'Udemy',
  'MuleSoft',
  'Affirm',
  'DocuSign',
  'Cruise',
  'Roblox',
  'Riot Games',
  'Wish',
  'Instacart',
  'Credit Karma',
  'Lending Club',
  'Box',
  'Medallia',
  'DoorDash',
  'Gusto',
  'Segment',
  'Asana',
  'Postmates',
  'Stripe',
  'Coinbase',
  'Snowflake',
  'Unity Technologies',
  'Giphy',
  'Robinhood',
  'Snap',
  'Affirm',
  'AppLovin',
  'BigCommerce',
  'Chime',
  'Airtable',
  'HashiCorp',
  'Mixpanel',
  'Figma',
  'Squarespace',
  'Asana',
  'Patreon',
  'Grammarly',
  'Instacart',
  'JFrog',
  'Quora',
  'Rubrik',
  'Robinhood',
  'UiPath',
  'Zapier',
  'Sumo Logic',
  'Tanium',
  'PagerDuty',
  'Confluent',
  'Palo Alto Networks',
  'MongoDB',
  'PagerDuty',
  'Datadog',
  'Robinhood',
  'AppDynamics',
  'Intercom',
  'Airbnb',
  'Slack',
  'Coinbase',
  'Zendesk',
  'Reddit',
  'Twilio',
  'Yelp',
  'Cloudflare',
  'Roku',
  'SurveyMonkey',
  'Wish',
  'StockX',
  'Auth0',
  'Calendly',
  'Quip',
  'Duolingo',
  'Nextdoor',
  'Vimeo',
  'Ripple',
  'Instacart',
  'Lucid Motors',
  'Canva',
  'DoorDash',
  'TripActions',
  'C3.ai',
  'Affirm',
  'Sumo Logic',
  'Celonis',
  'Opendoor',
  'UiPath',
  'Toast',
  'Root Insurance',
  'Samsara',
  'Snowflake',
  'OneTrust',
  'Confluent',
  'Rubrik',
  'Darktrace',
  'KeepTruckin',
  'Tanium',
  'WalkMe',
  'GitLab',
  'Amplitude',
  'Outreach',
  'Wish',
  'Opendoor',
  'Snowflake',
  'Coinbase',
  'UiPath',
  'Confluent',
  'GitLab',
  'Roblox',
  'Rivian',
  'HashiCorp',
  'ZipRecruiter',
  'Squarespace',
  'Plaid',
  'Instacart',
  'Riot Games',
  'Affirm',
  'Nextdoor',
  'Patreon',
  'UiPath',
  'Samsara',
  'Checkr',
  'Instacart',
  'Blend',
  'Toast',
  'Robinhood',
  'Ironclad',
  'Squarespace',
  'Confluent',
  'Intercom',
  'Toast',
  'Amplitude',
  'Root Insurance',
  'Figma',
  'Lucid Motors',
  'Braintree',
  'WalkMe',
  'TripActions',
  'Keeper Security',
  'Databricks',
  'Sonos',
]

export const jobTitles = [
  'Software Engineer',
  'Data Scientist',
  'Product Manager',
  'UX Designer',
  'DevOps Engineer',
  'Frontend Developer',
  'Backend Developer',
  'Systems Administrator',
  'Network Engineer',
  'QA Engineer',
  'Technical Support Specialist',
  'Cybersecurity Analyst',
  'Cloud Architect',
  'Database Administrator',
  'Machine Learning Engineer',
  'UI Designer',
  'Business Analyst',
  'Scrum Master',
  'Site Reliability Engineer',
  'AI Engineer',
]

export const humanFirstNames = [
  'Emily',
  'Liam',
  'Olivia',
  'Noah',
  'Emma',
  'Jackson',
  'Ava',
  'Aiden',
  'Isabella',
  'Lucas',
  'Mia',
  'Ethan',
  'Sophia',
  'Mason',
  'Amelia',
  'Logan',
  'Harper',
  'Jacob',
  'Evelyn',
  'Jack',
  'Abigail',
  'Daniel',
  'Emily',
  'Benjamin',
  'Charlotte',
  'William',
  'Sofia',
  'Michael',
  'Avery',
  'Alexander',
  'Elizabeth',
  'Elijah',
  'Ella',
  'James',
  'Scarlett',
  'Henry',
  'Grace',
  'Oliver',
  'Chloe',
  'Sebastian',
  'Victoria',
  'Samuel',
  'Riley',
  'David',
  'Aria',
  'Joseph',
  'Lily',
  'Carter',
  'Hannah',
  'Jayden',
  'Layla',
  'John',
  'Addison',
  'Gabriel',
  'Natalie',
  'Dylan',
  'Zoe',
  'Luke',
  'Penelope',
  'Anthony',
  'Brooklyn',
  'Isaac',
  'Savannah',
  'Grayson',
  'Audrey',
  'Wyatt',
  'Samantha',
  'Leo',
  'Leah',
  'Lincoln',
  'Stella',
  'Nathan',
  'Claire',
  'Adam',
  'Ellie',
  'Christopher',
  'Paisley',
  'Andrew',
  'Skylar',
  'Aaron',
  'Violet',
  'Eli',
  'Madeline',
  'Joshua',
  'Lucy',
  'Dominic',
  'Aurora',
  'Hunter',
  'Nova',
  'Christian',
  'Serenity',
  'Levi',
  'Emilia',
  'Evan',
  'Hazel',
  'Jaxon',
  'Brooklynn',
  'Cameron',
  'Bella',
  'Nicholas',
  'Caroline',
  'Julian',
  'Genesis',
  'Ezekiel',
  'Aaliyah',
  'Colton',
  'Kylie',
  'Angel',
  'Aubrey',
  'Miles',
  'Alyssa',
  'Micah',
  'Addyson',
  'Eric',
  'Madelyn',
  'Morgan',
  'Samantha',
  'Aaron',
  'Alexa',
  'Jace',
  'Isabelle',
  'Brayden',
  'Adeline',
  'Tyler',
  'Alexandra',
  'Cole',
  'Maya',
  'Bentley',
  'Gianna',
  'Landon',
  'Reagan',
  'Xavier',
  'Gabriella',
  'Diego',
  'Ariel',
  'Jayce',
  'Jasmine',
  'Adam',
  'Mackenzie',
  'Carson',
  'Elena',
  'Asher',
  'Katherine',
  'Kayden',
  'Julia',
  'Mateo',
  'Ivy',
  'Jason',
  'Taylor',
  'Chase',
  'Eva',
  'Theodore',
  'Naomi',
  'Elias',
  'Athena',
  'Maverick',
  'Valentina',
  'Roman',
  'Autumn',
  'Leonardo',
  'Nevaeh',
  'Josiah',
  'Sadie',
  'Axel',
  'Piper',
  'Santiago',
  'Peyton',
  'Giovanni',
  'Nora',
  'Luca',
  'Kaylee',
  'Edward',
  'Ruby',
  'Jameson',
  'Makayla',
  'Jordan',
  'Molly',
  'Dominick',
  'Sydney',
  'Caleb',
  'Elise',
  'Leo',
  'Lauren',
  'Jaxson',
  'Adalynn',
  'Ezra',
  'Ayla',
  'Owen',
  'Harmony',
  'Cole',
  'Eliza',
  'Bennett',
  'Alexis',
  'Jesse',
  'Isabel',
  'Joel',
  'Elliana',
  'Caden',
  'Natalia',
  'Carter',
  'London',
  'Brooks',
  'Raelynn',
  'Zachary',
  'Leilani',
  'Greyson',
  'Faith',
  'Luke',
  'Jocelyn',
  'Everett',
  'Mariah',
  'Parker',
  'Juliana',
  'Isaiah',
  'Lilly',
  'Colin',
  'Angelina',
  'Jayceon',
  'Aurora',
  'Levi',
  'Callie',
  'Xander',
  'Adelyn',
  'Damian',
  'Isabela',
  'Silas',
  'Giuliana',
  'Kai',
  'Allison',
  'Vincent',
  'Jennifer',
  'Micah',
  'Melanie',
  'Hayden',
  'Jade',
  'Maxwell',
  'Laila',
  'Bryson',
  'Mckenzie',
  'Gage',
  'Adalyn',
  'George',
  'Ana',
  'Brian',
  'Amina',
  'Max',
  'Gabrielle',
  'Kingston',
  'Angela',
  'Ashton',
  'Londyn',
  'Braxton',
  'Mya',
  'Andres',
  'Teagan',
  'Abel',
  'Sara',
  'Ezekiel',
  'Mariana',
  'Alex',
  'Michelle',
  'Grant',
  'Amara',
  'Zane',
  'Jennifer',
  'Calvin',
  'Alana',
  'Everett',
  'Jacqueline',
  'Jaylen',
  'Summer',
  'Cayden',
  'Charlie',
  'Wesley',
  'Margaret',
  'Jakob',
  'Giselle',
  'Hudson',
  'Lila',
  'Bryce',
  'Adelaide',
  'Karter',
  'June',
  'Maddox',
  'Daniela',
  'Malachi',
  'Jayla',
  'Emmanuel',
  'Tessa',
  'Rhett',
  'Adriana',
  'Abraham',
  'Alivia',
  'Beau',
  'Aria',
  'Jay',
  'Vivian',
  'Milo',
  'Morgan',
  'Karson',
  'Brielle',
  'Archer',
  'Mackenzie',
  'Graham',
  'Shelby',
  'Dean',
  'Miranda',
  'Israel',
  'Kaitlyn',
  'Ryder',
  'Liliana',
  'August',
  'Kamryn',
  'Arthur',
  'Hayden',
  'Ivan',
  'Delilah',
  'Erick',
  'Veronica',
  'Paul',
  'Francesca',
  'Colton',
  'Hope',
  'Derek',
  'Skyler',
  'Rylan',
  'Karen',
  'Sawyer',
  'Isla',
  'Holden',
  'Amber',
  'Gunner',
  'Miriam',
  'Knox',
  'Emery',
  'Hayden',
  'Angelica',
  'Muhammad',
  'Iris',
  'Leon',
  'Eleanor',
  'Finn',
  'Malia',
  'Theo',
  'Catalina',
  'Jasper',
  'Camilla',
  'Jake',
  'Cassandra',
  'Emmett',
  'Zara',
  'Chance',
  'Josie',
  'Oscar',
  'Leila',
  'Callum',
  'Catherine',
  'Tanner',
  'Angelique',
  'Kaden',
  'Lucia',
  'Lukas',
  'Bianca',
  'Kyle',
  'Parker',
  'Gael',
  'Irene',
  'River',
  'Danielle',
  'Adriel',
  'Lena',
  'Zayden',
  'Josephine',
  'Maverick',
  'Harley',
  'Alan',
  'Gabriela',
  'Xander',
  'Georgia',
  'Tucker',
  'Nina',
  'Kameron',
  'Olive',
  'Jayson',
  'Nyla',
  'Edgar',
  'Adrianna',
  'Joey',
  'Fatima',
  'Leonel',
  'Laura',
  'Corbin',
  'Talia',
  'Frank',
  'Selena',
  'Daxton',
  'Skye',
  'Armando',
  'Haven',
  'Reid',
  'Camille',
  'Julius',
  'Hope',
  'Miles',
  'Eloise',
  'Dawson',
  'Zuri',
  'Davis',
  'Brianna',
  'Jett',
  'Rowan',
  'Angelo',
  'Nadia',
  'Nash',
  'Myla',
  'Barrett',
  'Kendra',
  'Ismael',
  'Evangeline',
  'Orion',
  'Joy',
  'Jensen',
  'Heidi',
  'Rory',
  'Esmeralda',
  'Ronan',
  'Phoebe',
  'Atlas',
  'Yaretzi',
  'Jeffrey',
  'Clara',
  'Sergio',
  'Anastasia',
  'Kyrie',
  'Luna',
  'Marco',
  'Desiree',
  'Matteo',
  'Serena',
  'Zander',
  'Elsie',
  'Troy',
  'Lana',
  'Phillip',
  'Lilah',
  'Dexter',
  'Carmen',
  'Brantley',
  'Selah',
  'Leland',
  'Ada',
  'Derrick',
  'Makenzie',
  'Elliot',
  'Juliette',
  'Dominik',
  'Raegan',
  'Kane',
  'Adeline',
  'Ellis',
  'Arielle',
  'Sullivan',
  'Alayna',
  'Cohen',
  'Lola',
  'Trevor',
  'Mariana',
  'Dustin',
  'Kyla',
  'Harvey',
  'Cecilia',
  'Enzo',
  'Jayleen',
  'Tony',
  'Annie',
  'Luka',
  'Aniya',
  'Milo',
  'Juliet',
  'Kieran',
  'Dayana',
  'Jared',
  'Leighton',
  'Warren',
  'Fiona',
  'Gideon',
  'Katie',
  'Kyler',
  'Daphne',
  'Mauricio',
  'Maggie',
  'Hugo',
  'Julie',
  'Uriel',
  'Braylee',
  'Fernando',
  'Nayeli',
  'King',
  'Madilyn',
  'Dante',
  'Jazmin',
  'Jaiden',
  'Gemma',
  'Mekhi',
  'Willa',
  'Rocco',
  'Julianna',
  'Khalil',
  'Alina',
  'Lennox',
  'Elaina',
  'Zayn',
  'Angie',
  'Beckett',
  'Harper',
  'Felix',
  'Samara',
  'Romeo',
  'Presley',
  'Cristian',
  'Macy',
  'Raiden',
  'Caitlyn',
  'Hendrix',
  'Nova',
  'Lorenzo',
  'Raelyn',
  'Darius',
  'Elle',
  'Tristan',
  'Lilith',
  'Cyrus',
  'Joselyn',
  'Tate',
  'Leia',
  'Maximus',
  'Kaydence',
  'Cash',
  'Ariella',
  'Rodrigo',
  'Holly',
  'Anderson',
  'Kendra',
  'Major',
  'Nancy',
  'Armani',
  'Marlee',
  'Kason',
  'Lindsay',
  'Skyler',
  'Lacey',
  'Dean',
  'Aubree',
  'Kian',
  'Kiera',
  'Dax',
  'Hanna',
  'Gerardo',
  'Ember',
  'Joaquin',
  'Alisha',
  'Elliot',
  'Addilyn',
  'Emilio',
  'Virginia',
  'Jax',
  'Emersyn',
  'Camden',
  'Cynthia',
  'Brock',
  'Angelique',
  'Edwin',
  'Lainey',
  'Kellan',
  'Anya',
  'Avery',
  'Carly',
  'Dillon',
  'Adelynn',
  'Lane',
  'Isis',
  'Dallas',
  'Emilee',
  'Abel',
  'Maddison',
  'Jett',
  'Janelle',
  'Beckham',
  'Elisa',
  'Griffin',
  'Felicity',
  'Amari',
  'Cassandra',
  'Keegan',
  'Tatum',
  'Phoenix',
  'Jaelynn',
  'Kellan',
  'Camryn',
  'Reed',
  'Yara',
  'Kylan',
  'Dulce',
  'Ronald',
  'Zariah',
  'Giovani',
  'Lilian',
  'Jamison',
  'Mabel',
  'Maximilian',
  'Elisabeth',
  'Dennis',
  'Joyce',
  'Bowen',
  'Kristen',
  'Augustus',
  'Nia',
  'Kohen',
  'Kiera',
  'Taylor',
  'Jazlyn',
  'Porter',
  'Esperanza',
  'Francisco',
  'Annalise',
  'Malik',
  'Sasha',
  'Colby',
  'Kamila',
  'Atlas',
  'Lorelei',
  'Amos',
  'Lacey',
  'Otto',
  'Brynn',
  'Reginald',
  'Bristol',
  'Gustavo',
  'Kara',
  'Kelvin',
  'Erika',
  'Emery',
  'Helen',
  'Brayan',
  'Ainsley',
  'Boston',
  'Kamryn',
  'Lance',
  'Elisabeth',
  'Jamari',
  'Karen',
  'Alonso',
  'Sariah',
  'Keaton',
  'Kristina',
  'Jalen',
  'Arya',
  'Nikolas',
  'Celeste',
  'Sincere',
  'Jaycee',
  'Kole',
  'Marilyn',
  'Noel',
  'Elaine',
  'Ricky',
  'Caitlin',
  'Trey',
  'Alayna',
  'Lionel',
  'Susan',
  'Lucca',
  'Nayeli',
  'Lawson',
  'Ember',
  'Kendrick',
  'Ruth',
  'Alfredo',
  'Liliana',
  'Lewis',
  'Celine',
  'Byron',
  'Alondra',
  'Warren',
  'Jane',
  'Aaden',
  'Phoenix',
  'Quentin',
  'Sage',
  'Morgan',
  'Anika',
  'Ari',
  'Kyra',
  'Issac',
  'Raelyn',
  'Joe',
  'Baylee',
  'Kade',
  'Braelyn',
  'Samson',
  'Lara',
  'Dexter',
  'Alison',
  'Prince',
  'Nola',
  'Jonas',
  'Raven',
  'Tomas',
  'Yasmin',
  'Micheal',
  'Haleigh',
  'Kian',
  'Lillie',
  'Ariel',
  'Dayami',
  'Royce',
  'Evangeline',
  'Mohamed',
  'Anahi',
  'Rhys',
  'Ansley',
  'Ramon',
  'Frida',
  'Orlando',
  'Kailey',
  'Nasir',
  'Journey',
  'Callan',
  'Sylvia',
  'Madden',
  'Scarlet',
  'Jaxton',
  'Anabella',
  'Arturo',
  'Madilynn',
  'Billy',
  'Danna',
  'Gerald',
  'Vivienne',
  'Kamari',
  'Marie',
  'Leighton',
  'Zoey',
  'Willie',
  'Caylee',
  'Travis',
  'Jaylene',
  'Jagger',
  'Melany',
  'Kody',
  'Virginia',
  'Cristopher',
  'Lilian',
  'Taylor',
  'Jessie',
  'Dariel',
  'Ashlyn',
  'Brett',
  'Anabelle',
  'Cassius',
  'Miracle',
  'Walker',
  'Meredith',
  'Ayaan',
  'Kiera',
  'Muhammad',
  'Holly',
  'Deacon',
  'Liana',
  'Johan',
  'Aileen',
  'Soren',
  'Amiyah',
  'Dominique',
  'April',
  'Raylan',
  'Jazmine',
  'Lennon',
  'Marisol',
  'Bo',
  'Lacey',
  'Dane',
  'Kailyn',
  'Douglas',
  'Paislee',
  'Sutton',
  'Saniyah',
  'Barrett',
  'Harlow',
  'Vicente',
  'Joy',
  'Flynn',
  'Alisson',
  'Hamza',
  'Arabella',
  'Jamison',
  'Jayde',
  'Kendall',
  'Crystal',
  'Sylas',
  'Annika',
  'Curtis',
  'Cynthia',
  'Kaison',
  'Jessie',
  'Kobe',
  'Malia',
  'Alfred',
  'Jaylin',
  'Jamal',
  'Savanna',
  'Harry',
  'Lizbeth',
  'Maison',
  'Ariah',
  'Mohammed',
  'Zaniyah',
  'Marvin',
  'Elle',
  'Alvin',
  'Adele',
  'Rayan',
  'Brenda',
  'Talon',
  'Zahra',
  'Keagan',
  'Halle',
  'Boston',
  'Kenzie',
  'Ares',
  'Sariah',
  'Toby',
  'Clarissa',
  'Blaine',
  'Sky',
  'Crosby',
  'Marina',
  'Mathias',
  'Mckinley',
  'Douglas',
  'Lennon',
  'Jaziel',
  'Virginia',
  'Kendall',
  'Lainey',
  'Santino',
  'Johanna',
  'Izaiah',
  'Joselyn',
  'Alec',
  'Aisha',
  'Bobby',
  'Elianna',
  'Johan',
  'Alani',
  'Dorian',
  'Kaiya',
  'Marcelo',
  'Maliah',
  'Eden',
  'Aimee',
  'Tony',
  'Virginia',
  'Donald',
  'Kylee',
  'Casen',
  'Aniyah',
  'Kase',
  'Lina',
  'Quinton',
  'Aya',
  'Stefan',
  'Iliana',
  'Santos',
  'Jaelyn',
  'Aldo',
  'Regina',
  'Mekhi',
  'Sloan',
  'Houston',
  'Anabella',
  'Jaycob',
  'Alivia',
  'Musa',
  'Lilia',
  'Rex',
  'Analia',
  'Rogelio',
  'Mara',
  'Quincy',
  'Sonia',
  'Wilson',
]
